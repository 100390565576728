import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import FullWidthImage from "../components/FullWidthImage";
import DisplayContentHeader from "../components/DisplayContentHeader";
import { kebabCase } from "lodash";
import DisplayContent from "../components/DisplayContent";
import DisplayHeroVideoOrImage from "../components/DisplayHeroVideoOrImage";
import SmallImage from "../components/SmallImage";
import BlogList from "../components/BlogList";
import Pagination from "../components/Pagination";
import { nanoid } from "nanoid";
const pluralize = require("pluralize");

// eslint-disable-next-line
export const TagsPostTemplate = ({
  title,
  subtitle,
  footer,
  images,
  previewContent,
  breadcrumbs,
  options,
  helmet,
  layout,
  videos,
  header,
  posts,
  tagOverride,
  tags,
  authors,
  pageContext,
}) => {
  let templateName = "tags-post",
    heroVideo,
    heroImage,
    smallImage,
    footerImage,
    relatedLinks,
    renderMode = "renderDivDateSummaryImage",
    renderSize = 2,
    paginationPosition = "below",
    renderWeight = "normal";

  if (header && header.layout && header.layout.render) {
    if (header.layout.render.tags) {
      renderMode = header.layout.render.tags;
    }
    if (header.layout.render.size) {
      renderSize = header.layout.render.size;
    }
    if (header.layout.render.weight) {
      renderWeight = header.layout.render.weight;
    }

    if (header.layout.render.position) {
      paginationPosition = header.layout.render.position;
    }

    layout = header.layout;
  }

  let tag = pageContext.tag;

  let capitalizedTag = tag.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
    letter.toUpperCase()
  );

  if (tagOverride && tagOverride !== tag) {
    capitalizedTag = tagOverride;
  }

  let relatedTag = relatedTags(tag, tags, 10);
  if (relatedTag.length) {
    if (true) {
      relatedTag = relatedTag.sort();
    }

    relatedLinks = relatedTag.map((tag) => (
      <React.Fragment key={nanoid()}>
        <Link title={tag} className="tag-join" to={`/tag/${kebabCase(tag)}`}>
          <>{tag}</>
        </Link>
      </React.Fragment>
    ));
  }

  if (images) {
    if (images.hero) {
      heroImage = getImage(images.hero) || images.hero;
    }
    if (images.footer) {
      footerImage = getImage(images.footer) || images.footer;
    }
    if (images.smallImage) smallImage = images.smallImage;
  }

  if (videos && videos.hero) heroVideo = videos.hero;

  const showTitle = options.showTitle;

  let showDisplayContentHeader = true;
  if (
    !options.showDisplayContentHeader &&
    options.showDisplayContentHeader !== undefined &&
    options.showDisplayContentHeader !== null
  ) {
    showDisplayContentHeader = false;
  }

  // need to auto-detect thisand figure out how to prevent swaps

  breadcrumbs.grandparent = "Blog";
  breadcrumbs.grandparentSlug = "/blog";
  breadcrumbs.parent = "Tags";
  breadcrumbs.parentSlug = "/tags";

  // calculate the top performing tags
  // calculate the related tags

  return (
    <div>
      {helmet || null}

      <DisplayHeroVideoOrImage
        heroVideo={heroVideo}
        heroImage={heroImage}
        title={capitalizedTag}
        subtitle={pluralize(title, 1)}
        imgPosition="center"
        showTitle={showTitle}
        showTitlesSwapped={true}
      />

      <section className={`section section--gradient ${templateName}`}>
        <div className="container">
          <div className="content">
            <div className="content is-hidden-tablet">
              <SmallImage
                image={smallImage}
                imageTitle={`${title} ${subtitle}`}
                className="pt-0 m-0"
              />
            </div>
            <div
              className={`content m-0 p-0 ${
                showDisplayContentHeader ? "" : "is-hidden"
              }`}
            >
              <SmallImage
                image={smallImage}
                imageTitle={`${title} ${subtitle}`}
                className="image small-image is-pulled-right pt-0 mt-0 is-hidden-mobile"
              />
              <div className="header">
                <DisplayContentHeader
                  title={`${capitalizedTag}`}
                  breadcrumbs={breadcrumbs}
                  header={header}
                  showColon={true}
                />
              </div>
            </div>

            <DisplayContent
              templateName={templateName}
              previewContent={previewContent}
              body={
                <>
                  {relatedLinks ? (
                    <>
                      <div className="content">
                        <p>
                          {" "}
                          The tag "<>{tag}</>" relates to:{" "}
                          {andJoin(relatedLinks)}
                          For more tags, view our complete{" "}
                          <Link to="/tags/" title="Tags">
                            tag index
                          </Link>
                          .
                        </p>

                        <p>
                          The following blog{" "}
                          {pluralize("entry", relatedLinks.length)} have been
                          tagged "<>{tag}</>".
                        </p>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              }
              layout={layout}
              element={
                <>
                  <BlogList
                    posts={posts}
                    authors={authors}
                    title={`Blog ${pluralize("Post", posts.length)}:`}
                    emptyMessage="This author has not yet contributed."
                    renderMode={renderMode}
                    renderSize={renderSize}
                    renderWeight={renderWeight}
                  />
                </>
              }
              pages={posts ? posts.length : 1}
              pagination={<Pagination pageContext={pageContext} />}
              paginationPosition={paginationPosition}
            />
          </div>
        </div>
      </section>

      {footerImage ? (
        <FullWidthImage
          img={footerImage}
          subheading={footer}
          imgPosition="center"
          className="footer-image-text"
          showTitle={true}
        />
      ) : null}
    </div>
  );
};

TagsPostTemplate.propTypes = {
  helmet: PropTypes.object,
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  subtitle: PropTypes.string,
  footer: PropTypes.string,
  images: PropTypes.shape({
    hero: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImageUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    footer: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  author: PropTypes.string,
  creationDate: PropTypes.string,
  sortDate: PropTypes.string,
  flags: PropTypes.shape({
    published: PropTypes.bool,
    featured: PropTypes.bool,
  }),
  options: PropTypes.shape({
    showTitle: PropTypes.bool,
    showSummary: PropTypes.bool,
    showTags: PropTypes.bool,
    showComments: PropTypes.bool,
    showAuthorSummary: PropTypes.bool,
    showPrevNext: PropTypes.bool,
    showPopular: PropTypes.bool,
    showRelated: PropTypes.bool,
  }),
  breadcrumbs: PropTypes.shape({
    parent: PropTypes.string,
    parentSlug: PropTypes.string,
    grandparent: PropTypes.string,
    grandparentSlug: PropTypes.string,
  }),
  body: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  summary: PropTypes.string,
};

const TagsPost = ({ data, pageContext }) => {
  let { markdownRemark: post } = data;
  let { allMarkdownRemark: posts } = data;
  let { header } = data;
  let { tags } = data;
  let { site } = data;
  let { authors } = data;
  let { tagConfig } = data;

  let tagConfigs = [];

  if (tagConfig.frontmatter) {
    tagConfig.frontmatter.configTags.forEach((tag) => {
      if (tag.title && !tag.tag) {
        tag.tag = tag.title;
      }
      if (tag.tag) {
        if (!tagConfigs[tag.tag]) {
          tagConfigs[tag.tag] = {};
        }
        tagConfigs[tag.tag].title = tag.title;
        if (tag.hero) {
          tagConfigs[tag.tag].hero = tag.hero;
        }
      }
    });
  }
  //console.log(tagConfigs);
  //console.log(tagConfigs[pageContext.tag]);

  if (tagConfigs[pageContext.tag]) {
    if (
      pageContext.tag &&
      post.frontmatter.images &&
      post.frontmatter.images.hero &&
      tagConfigs[pageContext.tag].hero
    ) {
      post.frontmatter.images.hero = tagConfigs[pageContext.tag].hero;
    }
    pageContext.tag = tagConfigs[pageContext.tag].title;
  }

  let imageSrc;

  if (
    post.frontmatter.images &&
    post.frontmatter.images.hero &&
    post.frontmatter.images.hero.childImageSharp
  ) {
    imageSrc =
      post.frontmatter.images.hero.childImageSharp.gatsbyImageData.images
        .fallback.src;
  }

  if (!post.frontmatter.titles) post.frontmatter.titles = {};
  //  config = config.frontmatter.site;
  //  site = site.siteMetadata.property;
  return (
    <Layout>
      <TagsPostTemplate
        title={post.frontmatter.title}
        subtitle={post.frontmatter.titles.subtitle}
        footer={post.frontmatter.titles.footer}
        images={post.frontmatter.images}
        author={post.frontmatter.author}
        creationDate={post.frontmatter.creationDate}
        options={post.frontmatter.options}
        breadcrumbs={post.frontmatter.breadcrumbs}
        body={post.html}
        pageContext={pageContext}
        helmet={
          <Seo
            title={post.frontmatter.title}
            summary={post.frontmatter.summary}
            image={imageSrc}
            article={false}
          />
        }
        posts={posts.edges}
        header={header.frontmatter}
        tags={tags.edges}
        tagOverride={pageContext.tag}
        site={site}
        authors={authors.nodes}
      />
    </Layout>
  );
};

TagsPost.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TagsPost;

export const tagsPostQuery = graphql`
  query TagsPost($tag: String, $skip: Int!, $limit: Int!) {
    markdownRemark(
      frontmatter: { advanced: { templateKey: { eq: "tags-page" } } }
    ) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        titles {
          subtitle
          footer
        }
        images {
          hero {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          footer {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImage {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImageUrl
        }
        options {
          showTitle
          showTitlesSwapped
          showDisplayContentHeader
        }
        breadcrumbs {
          parent
          parentSlug
          grandparent
          grandparentSlug
        }
        summary
      }
    }
    tagConfig: markdownRemark(
      frontmatter: {
        advanced: {
          configKey: { eq: "tags" }
          templateKey: { eq: "configuration" }
        }
      }
    ) {
      frontmatter {
        configTags {
          tag
          title
          hero {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
    header: markdownRemark(
      frontmatter: {
        advanced: {
          configKey: { eq: "site" }
          templateKey: { eq: "configuration" }
        }
      }
    ) {
      frontmatter {
        layout {
          position
          columns
          render {
            size
            tags
            position
            separator
            weight
          }
        }
        breadcrumbs {
          positionClass
          separatorClass
          position
          showDate
          showCurrent
        }
      }
    }
    tags: allMarkdownRemark(
      sort: { fields: [frontmatter___creationDate], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            category
            author
            tags
            photos {
              showGallery
              photo {
                image {
                  id
                }
              }
            }
          }
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___creationDate], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
      skip: $skip
      limit: $limit
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            titles {
              subtitle
              footer
            }
            category
            author
            creationDate
            summary
            tags
            images {
              hero {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
      }
    }
    authors: allMarkdownRemark(
      filter: {
        frontmatter: { advanced: { templateKey: { eq: "author-post" } } }
      }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          name
        }
      }
    }
  }
`;

function andJoin(array) {
  let last = array.pop();

  let working = array.map((item) => {
    return (
      <React.Fragment key={nanoid()}>
        <span className="tag-join">{item}</span>,{" "}
      </React.Fragment>
    );
  });

  working.push(
    <>
      and{" "}
      <React.Fragment key={nanoid()}>
        <span className="tag-join">{last}</span>.{" "}
      </React.Fragment>
    </>
  );
  return working;
}

function relatedTags(tag, posts, max = 5) {
  // count tags
  let counts = [];

  posts.forEach((post) => {
    const tags = post.node.frontmatter.tags;
    tags.forEach((tag) => {
      counts[tag] ? counts[tag]++ : (counts[tag] = 1);
    });
  });

  delete counts[tag];

  // build 2D temp array for sorting
  // currently numeric desc

  var tuples = [];

  for (var key in counts) tuples.push([key, counts[key]]);

  tuples.sort(function (b, a) {
    a = a[1];
    b = b[1];

    return a < b ? -1 : a > b ? 1 : 0;
  });

  // return sorted array of tags

  let related = [];
  let k = null;
  //let v = null;

  for (var i = 0; i < tuples.length && related.length < max; i++) {
    k = tuples[i][0];
    related.push(k);
  }

  return related;
}
